<nav class="flex items-center gap-x-1" aria-label="Pagination">
  <button 
    type="button" 
    class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-1.5 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
    aria-label="Previous"
    [disabled]="isPreviousDisabled"
    (click)="onPrevious()">
    <svg class="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="m15 18-6-6 6-6"></path>
    </svg>
    <span>Prev</span>
  </button>

  <div class="flex items-center gap-x-1">
    <!-- Show the first page -->
    <button 
      type="button"
      [ngClass]="{'bg-gray-200': currentPage === 1, 'text-gray-800': currentPage !== 1}"
      class="min-h-[38px] min-w-[38px] flex justify-center items-center py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-100"
      (click)="goToPage(1)">
      1
    </button>

    <!-- Show ellipsis if the current page is not close to the first page -->
    <span *ngIf="currentPage > 4" class="min-h-[38px] min-w-[38px] flex justify-center items-center py-2 px-3 text-sm">...</span>

    <!-- Show pages dynamically around the current page -->
    <ng-container *ngFor="let page of getVisiblePages()">
      <button 
        *ngIf="page !== 1 && page !== totalPages"
        type="button"
        [ngClass]="{'bg-gray-200': currentPage === page, 'text-gray-800': currentPage !== page}"
        class="min-h-[38px] min-w-[38px] flex justify-center items-center py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-100"
        (click)="goToPage(page)">
        {{ page }}
      </button>
    </ng-container>

    <!-- Show ellipsis if the current page is not close to the last page -->
    <span *ngIf="currentPage < totalPages - 3" class="min-h-[38px] min-w-[38px] flex justify-center items-center py-2 px-3 text-sm">...</span>

    <!-- Show the last page -->
    <button 
      *ngIf="totalPages > 1"
      type="button"
      [ngClass]="{'bg-gray-200': currentPage === totalPages, 'text-gray-800': currentPage !== totalPages}"
      class="min-h-[38px] min-w-[38px] flex justify-center items-center py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-100"
      (click)="goToPage(totalPages)">
      {{ totalPages }}
    </button>
  </div>

  <button 
    type="button" 
    class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-1.5 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
    aria-label="Next"
    [disabled]="isNextDisabled"
    (click)="onNext()">
    <span>Nxt</span>
    <svg class="shrink-0 size-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="m9 18 6-6-6-6"></path>
    </svg>
  </button>
</nav>
