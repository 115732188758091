import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class CorporateAuthGuard implements CanActivate {
  loginUser: any;

  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(): Promise<boolean | UrlTree> {
    if (this.authService.isLoggedIn()) {
      this.loginUser = await this.authService.fetchLoginUser();
      if (this.loginUser && this.loginUser.type === '2' && this.loginUser.accountStatus !== 'applied') {
        return true;
      } else {
        return this.router.createUrlTree(['/auth/signin']);
      }
    }
    // Redirect to the sign-in page if not authenticated or not the correct user type
    return this.router.createUrlTree(['/auth/signin']);
  }
}
