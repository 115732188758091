<app-spinner *ngIf="isLoading"></app-spinner>
<header

  class="sticky top-0 inset-x-0 flex flex-wrap md:justify-start md:flex-nowrap z-[48] w-full bg-white border-b text-sm py-2.5 lg:ps-[260px] dark:bg-neutral-800 dark:border-neutral-700"
>
  <nav class="px-4 sm:px-6 flex basis-full items-center w-full mx-auto">
    <div class="me-5 lg:me-0 lg:hidden">
      <!-- Logo -->
      <a
        class="flex-none rounded-md text-xl inline-block font-semibold focus:outline-none focus:opacity-80"
        href="#"
        aria-label="Preline"
      >
        <a class="flex justify-center" href="#">
          <img
            class="w-28 h-auto"
            src="https://saraldiscount.com/assets/saral-discount-logo.svg"
            alt="saral-discount-logo"
          />
        </a>
      </a>
      <!-- End Logo -->
    </div>

    <div
      class="w-full flex items-center justify-end ms-auto md:justify-between gap-x-1 md:gap-x-3"
    >
      <div class="hidden md:block">
        <!-- Search Input -->
        
        <!-- End Search Input -->
      </div>

      <div class="flex flex-row items-center justify-end gap-3">
   
        

        <!-- Link Request dropdown -->
        <div class="hs-dropdown relative inline-flex" *ngIf="this.loginUser.type === '2'">
       
          <button
          (click)="viewLinkRequest()"
          id="hs-dropdown-with-title"  aria-haspopup="menu" aria-expanded="false" aria-label="Dropdown"
            type="button"
            class="size-[38px] relative inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
          >
          <svg
          class="shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M21.44 11.05l-9-9a5 5 0 0 0-7.07 7.07l9 9a3 3 0 0 0 4.24-4.24l-9-9" />
          <path d="M16 18.38L7.62 10" />
          
        </svg>
        <span *ngIf="linkRequestCount > 0" class="absolute top-[6px] end-0 inline-flex items-center py-0.5 px-1.5 rounded-full text-xs font-medium transform -translate-y-1/2 translate-x-1/2 bg-red-500 text-white">{{linkRequestCount}}</span>

        
            <span class="sr-only">Link Request</span>
         
          
          </button>
        
        
          <div (click)=" $event.stopPropagation()" class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden w-[40%] bg-white shadow-md rounded-lg mt-2 divide-y divide-gray-200" role="menu" aria-orientation="vertical" aria-labelledby="hs-dropdown-with-title" id="dropdown-menu">
            <div class="p-1">
              <!-- Header -->
              <span class="block pt-2 pb-1 px-3 text-xs font-medium uppercase text-gray-400">
               User Link Requests
              </span>
          
              <!-- Scrollable Table Body -->
              <div class="overflow-y-auto max-h-60">
                <table
                class="min-w-full divide-y divide-gray-200 dark:divide-neutral-700"
              >
                <thead class="bg-gray-50 dark:bg-neutral-800">
                  <tr>
                      
      
                    <th scope="col" class="px-6 py-3 text-start">
                      <div class="flex items-center gap-x-2">
                        <span
                          class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200"
                        >
                          Email
                        </span>
                      </div>
                    </th>
      
                    <th scope="col" class="px-6 py-3 text-start">
                      <div class="flex items-center gap-x-2">
                        <span
                          class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200"
                        >
                          Assign
                        </span>
                      </div>
                    </th>
                    <th scope="col" class="px-6 py-3 text-start">
                      <div class="flex items-center gap-x-2">
                        <span
                          class="text-xs font-semibold uppercase tracking-wide text-gray-800 dark:text-neutral-200"
                        >
                          Action
                        </span>
                      </div>
                    </th>
                 
      
      
                  
                  </tr>
                </thead>
      
                <tbody class="divide-y divide-gray-200 dark:divide-neutral-700">
                <tr *ngFor="let item of linkRequestList; let i = index;">
                  <td class="whitespace-nowrap">
                    <div class="px-6 py-3">
                      <span
                        class="block text-sm text-gray-500 dark:text-neutral-500"
                        >{{ item?.email }}</span
                      >
                    </div>
                  </td>
                  <td class="whitespace-nowrap">
                    <div class="px-6 py-3">
                      <select
                      class="py-3 px-4 pe-9 block border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                      (change)="changeEmp($event);$event.stopPropagation()"
                      name="employeeSelect"
                    >
                      <option value="">Select Employee</option>
                      <option *ngFor="let item of unAssignedUserList" [value]="item._id" >{{item.employeeCode}}</option>
                    </select>
                    
                    </div>
                  </td>
                  <td class="size-px whitespace-nowrap">
                    <div class="flex gap-2">

                      <span (click)="assign(item); $event.stopPropagation()" class="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500 cursor-pointer">
                        <svg class="size-2.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
                        </svg>
                        Accept
                      </span>

                    <span (click)="rejectRequest(item); $event.stopPropagation()" class="action-button" class="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-red-100 text-red-800 rounded-full dark:bg-red-500/10 dark:text-red-500 cursor-pointer">
                      <svg class="size-2.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                      </svg>
                      Reject
                    </span>
                    </div>
                  </td>
                  

                </tr>
                </tbody>
                <tfoot>
                  <tr *ngIf="!isLoading && !linkRequestList.length">
                    <td colspan="20" class="text-center text-red-400 py-3">
                      <i class="pi pi-database"></i>
                      No Records Found
                    </td>
                  </tr>
                </tfoot>
              </table>
             
              </div>
            </div>
          </div>
          
        </div>


  
        <button
        *ngIf="this.loginUser?.type === '1' || this.loginUser?.type === '2' || this.loginUser?.type === '3'"
        (click)="viewNotification()"
          type="button"
          class="size-[38px] relative inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
        >
          <svg
            class="shrink-0 size-4"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9"></path>
            <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0"></path>
          </svg>
          <span class="sr-only">Notifications</span>
        <span *ngIf="notificationCount > 0" class="absolute top-[6px] end-0 inline-flex items-center py-0.5 px-1.5 rounded-full text-xs font-medium transform -translate-y-1/2 translate-x-1/2 bg-red-500 text-white">{{notificationCount}}</span>

        </button>

        <!-- Dropdown -->
        <div
          class="hs-dropdown [--placement:bottom-right] relative inline-flex"
        >
          <button
            id="hs-dropdown-account"
            type="button"
            class="size-[38px] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-gray-800 focus:outline-none disabled:opacity-50 disabled:pointer-events-none dark:text-white"
            aria-haspopup="menu"
            aria-expanded="false"
            aria-label="Dropdown"
          >

          <img
          class="shrink-0 size-[38px] rounded-full"
          alt="Avatar"
          src="https://preline.co/assets/img/160x160/img1.jpg"
        />
       
        
          </button>

          <div
            class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg mt-2 dark:bg-neutral-800 dark:border dark:border-neutral-700 dark:divide-neutral-700 after:h-4 after:absolute after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4 before:start-0 before:w-full"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="hs-dropdown-account"
          >
            <div class="py-3 px-5 bg-gray-100 rounded-t-lg dark:bg-neutral-700">
              <p class="text-sm text-gray-500 dark:text-neutral-500">
                Signed in as
              </p>
              <p
                class="text-sm font-medium text-gray-800 dark:text-neutral-200"
              >
                {{ loginUser?.email }}
              </p>
            </div>
            <div class="p-1.5 space-y-0.5">
              <a
                *ngIf="
                  loginUser?.role === 'admin' ||
                  loginUser?.role === 'super-admin'
                "
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 dark:focus:text-neutral-300"
                [routerLink]="['/home/ha-admin/change-password']"
                routerLinkActive="router-link-active"
              >
                <i class="pi pi-cog"></i>
                Change Password
              </a>

              <a
                (click)="handleLogout()"
                class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700 dark:focus:text-neutral-300"
                href="javascript:void(0)"
              >
                <i class="pi pi-sign-out"></i>
                Log Out
              </a>
            </div>
          </div>
        </div>
        <!-- End Dropdown -->
      </div>
    </div>
  </nav>
</header>



<p-sidebar styleClass="w-[30rem]" [(visible)]="notificationSidebar" position="right">
  <h4 class="font-bold">Notifications</h4>
  <br>
  <ul *ngIf="!notificationList?.length" class="p-0 mx-0 mt-0 mb-4 list-none">
      <li class="text-center text-danger">
          <i class="pi pi-database"></i>
          No Records Found
      </li>
  </ul>
  <ul *ngFor="let notify of notificationList" class="p-0 mx-0 mt-0 mb-4 list-none">
      <li class="flex items-center py-2 border-bottom-1 surface-border">
          <div *ngIf="notify?.title === 'accepted'"
              class="w-3rem h-3rem flex items-center justify-content-center  border-circle mr-3 flex-shrink-0">
              <i class="pi pi-verified text-xl text-blue-500"></i>
          </div>
          <div *ngIf="notify?.title === 'request'"
              class="w-3rem h-3rem flex items-center justify-content-center  border-circle mr-3 flex-shrink-0">
              <i class="pi pi-user-plus text-xl text-blue-500"></i>
          </div>
          <div *ngIf="notify?.title === 'rejected' || notify?.title === 'end' "
              class="w-3rem h-3rem flex items-center justify-content-center border-circle mr-3 flex-shrink-0">
              <i class="pi pi-exclamation-triangle text-xl text-pink-500"></i>
          </div>
          <span class="text-900 line-height-3">
              {{notify?.message}}
              <div class="text-gray-500 text-sm">{{ notify?.createdAt | date:'short' }}</div>
          </span>
      </li>
  </ul>
</p-sidebar>