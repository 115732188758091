import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationService } from 'primeng/api';
import { ShareModule } from './share/share.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MessageService } from 'primeng/api';
import { ToastService } from './services/toast.service';
import { AppStorageService } from './services/app-storage-service';
import { UserService } from './services/user.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NetworkInterceptor } from './services/network.interceptor';
import { WaitingAuthGuard } from './guards/waitingGuard';
import { MerchantAuthGuard } from './guards/merchantAuthGuard';
import { CorporateAuthGuard } from './guards/corporateAuthGuard';
import { StaffAuthGuard } from './guards/staffAuthGuard';
import { HaAuthGuard } from './guards/haAuthGuard';
import { CanAccessGuard } from './guards/canAccessGuard';
import { SocketService } from './services/socket.service';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
const config: SocketIoConfig = {
  url: environment.socketUrl, options: {
    path: '/sdsocket',
    auth:{token:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImlkIjoiNjZjZGFhOTg4Mjg1MGEyYzdiZTliMjI2IiwiZW1haWwiOiJzdXJ5YWFyeWFsMjA4MUBnbWFpbC5jb20ifSwiaWF0IjoxNzI0NzU0ODcxLCJleHAiOjE3MjUzNTk2NzF9.x3p9DDG3sfo15480aD7TFUQZkaO1Ve1m-g3_5nx6_es',clientId:'66cdaa9882850a2c7be9b226'}
  }
}


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ShareModule,
    ReactiveFormsModule,
    HttpClientModule,
    SocketIoModule.forRoot(config),
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: NetworkInterceptor, multi: true },
    MessageService,
    ConfirmationService,
    ToastService,
    AppStorageService,
    UserService,
    WaitingAuthGuard,
    MerchantAuthGuard,
    CorporateAuthGuard,
    StaffAuthGuard,
    HaAuthGuard,
    CanAccessGuard,
    SocketService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
