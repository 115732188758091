import { Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AppStorageService } from 'src/app/services/app-storage-service';
import { UserRefreshService } from 'src/app/services/refresh-service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-left-side-bar',
  templateUrl: './left-side-bar.component.html',
  styleUrls: ['./left-side-bar.component.scss']
})
export class LeftSideBarComponent {

  loginUser: any;
  imgUrl: any = environment.imgUrl;
  countResult:any = []

  constructor(private toastService: ToastService, private router: Router, private appStorageService: AppStorageService, private userRefreshService: UserRefreshService, private userService:UserService) {


  }
  async ngOnInit() {
    await this.fetchLoginUser();
    this.userRefreshService.refresh$.subscribe(() => {
      this.fetchLoginUser();
    });
    this.userRefreshService.sidebarRefresh$.subscribe(() => {
    this.moduleWiseCount()
    });

    this.moduleWiseCount()

  }

  moduleWiseCount(){
    if(this.loginUser.role && (this.loginUser.role === 'admin' || this.loginUser.role === 'super-admin')){
      this.getModuleWiseCount()
    }

  }

  async fetchLoginUser() {
    const user = await this.appStorageService.getdata(environment.localSTORAGE.profile);
    // if key and value on the local storage then parse to json
    if (user) {
      this.loginUser = JSON.parse(user);
      console.log(this.loginUser)

    }

  }
  async handleLogout() {
    const confirm = await this.toastService.confirm(`Are you sure you want to Sign Out?`)
    if (confirm) {
      localStorage.removeItem(environment.localSTORAGE.profile)
      localStorage.removeItem(environment.localSTORAGE.token)
      this.router.navigate(['/auth/signin'])
    }

  }

  getModuleIcon(moduleName: string): string {
    const icons = {
      "Dashboard": "pi-home",
      "Users": "pi-users",
      "Admin Management": "pi-users",
      "Pending Verification": "pi-list",
      "Transaction History": "pi-wallet",
      "Activity Log": "pi-sitemap",
      "Contact Us": "pi-megaphone",
      "Sales Query": "pi-dollar",
      "Upgrade plan requests": 'pi-angle-double-up',

    };
    return icons[moduleName] || 'pi-folder';
  }

  formatModuleName(moduleName: string): string {
    return moduleName.toLowerCase().replace(/ /g, '-');
  }

  getItemCount(moduleId:any){
    if(this.countResult.length > 0){
      return this.countResult.filter(item => item._id === moduleId)[0]?.count || 0;
    }else{
      return 0;
    }
  }

  getModuleWiseCount(){
    this.userService.moduleEventCount().subscribe({
      next: (res) => {
        if (res && res.statusCode === 200) {
          this.countResult = res?.data?.result;
  

        } else {
          this.toastService.show(res.message);
        }
      },
      error: (err) => {
        this.toastService.show(err.message);
        console.error(err);
      }
    });


  }





}
