import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { SocketService } from './services/socket.service';
import { AuthService } from './services/auth.service';
import { UserRefreshService } from './services/refresh-service';
import { IStaticMethods } from 'preline/preline';
import { AppStorageService } from './services/app-storage-service';
import { environment } from 'src/environments/environment';
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit {
  constructor(private router: Router, private socketService:SocketService,private authService:AuthService, private userRefreshService:UserRefreshService, private appstorageservice:AppStorageService) { }
  public loginUser: any = '';

  async ngOnInit() {
    this.loginUser = await this.authService.fetchLoginUser();
    this.setupSocketListeners();
    this.userRefreshService.refresh$.subscribe(async() => {
      this.loginUser = await this.authService.fetchLoginUser();
      console.log(this.loginUser)
    });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          window.HSStaticMethods.autoInit();
        }, 100);
      }
    });
    console.log(this.loginUser)
  }
  title = 'saral-discount-client';



  setupSocketListeners() {
    // this is handle logout when admin sets account inactive
    this.socketService.inactive().subscribe((userId: any) => {
      // Check if this.loginUser is defined before accessing its properties
      if (this.loginUser && this.loginUser._id == userId) {
        this.handleLogout();
      }

    });

    this.socketService.delete().subscribe((userId: any) => {
      if (this.loginUser && this.loginUser._id == userId) {
        this.handleLogout();
      }


    });
  }
  handleLogout() {
    this.authService.logout();
  }
}
