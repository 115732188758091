import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner/spinner.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { LeftSideBarComponent } from './left-side-bar/left-side-bar.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { InputOtpModule } from 'primeng/inputotp';
import { RouterModule } from '@angular/router';
import {  ImageCropperModule } from 'ngx-image-cropper';
import { PaginationComponent } from './pagination/pagination.component';
import { ChartModule } from 'primeng/chart'
import { DropdownModule } from 'primeng/dropdown';
import { SidebarModule } from 'primeng/sidebar';
import { MultiSelectModule } from 'primeng/multiselect';
import { FileUploadModule } from 'primeng/fileupload';
import { CarouselModule } from 'primeng/carousel';


@NgModule({
  declarations: [
    SpinnerComponent,
    NotFoundComponent,
    LeftSideBarComponent,
    HeaderComponent,
    PaginationComponent
  ],
  imports: [
    CommonModule,
    ToastModule,
    ButtonModule,
    ConfirmDialogModule,
    RouterModule,
    SidebarModule,
    FormsModule
    
  ],
  exports: [
    SpinnerComponent,
    NotFoundComponent,
    ToastModule,
    ButtonModule,
    ConfirmDialogModule,
    LeftSideBarComponent,
    HeaderComponent,
    InputOtpModule,
    ReactiveFormsModule,
    FormsModule,
    ImageCropperModule,
    PaginationComponent,
    ChartModule,
    DropdownModule,
    SidebarModule,
    FileUploadModule,
    MultiSelectModule,
    CarouselModule
    
  ]
})
export class ShareModule { }
