import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppStorageService } from './app-storage-service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private appStorageService: AppStorageService,
    private router: Router
  ) {}

  public isLoggedIn() {
    return localStorage.getItem(environment.localSTORAGE.token) != null;
  }

  public async fetchLoginUser() {
    let user = await this.appStorageService.getdata(
      environment.localSTORAGE.profile
    );
    return user ? JSON.parse(user) : null;
  }
  public logout() {
    localStorage.removeItem(environment.localSTORAGE.profile);
    localStorage.removeItem(environment.localSTORAGE.token);
    this.router.navigate(['/auth/signin']);
  }
}
