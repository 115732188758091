import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppStorageService } from '../services/app-storage-service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class CanAccessGuard implements CanActivate {
  loginUser: any;
  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.authService.isLoggedIn()) {
      this.loginUser = await this.authService.fetchLoginUser();
      if (!this.loginUser) {
        this.router.navigate(['/auth/signin']); // Redirect to the login page
        return false;
      }
    }
    if (!this.loginUser?.adminRole?.moduleId) {
      this.router.createUrlTree(['/auth/signin']);
    }

    const moduleId = next.params['moduleId'];
    const matchedObject = this.loginUser.adminRole.moduleId.find(
      (obj) => obj._id === moduleId
    );
    if (matchedObject) {
      return true; // User has access to the module
    } else {
      this.router.createUrlTree(['/auth/signin']);
      return false;
    }
  }
}
