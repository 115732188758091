<div
  id="hs-application-sidebar"
  class="hs-overlay [--auto-close:lg] hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform w-[260px] h-full hidden fixed inset-y-0 start-0 z-[60] bg-white border-e border-gray-200 lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 dark:bg-neutral-800 dark:border-neutral-700"
  role="dialog"
  aria-label="Sidebar"
>
  <div class="relative flex flex-col h-full max-h-full px-3">
    <div class="px-6 pt-4 mb-4">
      <!-- Logo -->
      <a class="flex justify-center" href="#">
        <img
          class="w-32"
          src="https://saraldiscount.com/assets/saral-discount-logo.svg"
          alt="saral-discount-logo"
        />
      </a>

      <!-- End Logo -->
    </div>

    <!-- Content -->
    <div
      class="h-full overflow-y-auto [&amp;::-webkit-scrollbar]:w-2 [&amp;::-webkit-scrollbar-thumb]:rounded-full [&amp;::-webkit-scrollbar-track]:bg-gray-100 [&amp;::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&amp;::-webkit-scrollbar-track]:bg-neutral-700 dark:[&amp;::-webkit-scrollbar-thumb]:bg-neutral-500"
    >

    <div class="flex items-center justify-center mb-4">
      <div class="flex flex-col items-center">
        <!-- Circle Profile Image -->
        <div class="w-24 h-24 rounded-full overflow-hidden border-2 border-gray-300">
          <img *ngIf="loginUser?.logo" [src]="imgUrl + loginUser?.logo" class="w-full h-full object-cover"
           />
      <img *ngIf="!loginUser?.logo" src="https://preline.co/assets/img/160x160/img1.jpg" class="w-full h-full object-cover"
           />
        </div>
        <!-- User Info (optional) -->
        <div class="mt-4 text-center">
          <h2 class="text-xl font-semibold text-gray-900">{{loginUser?.companyBusinessName ? loginUser?.companyBusinessName : loginUser?.fullName }}</h2> <!-- Name -->
          <p class="text-sm text-gray-500">{{loginUser?.companyBusinessName ? loginUser?.email : loginUser?.email }}</p> <!-- Role or Status -->
        </div>
      </div>
    </div>
    
      <!-- Welcome Page -->
      <nav
        *ngIf="loginUser?.accountStatus === 'applied'"
        class="hs-accordion-group p-3 w-full flex flex-col flex-wrap"
        data-hs-accordion-always-open=""
      >
        <ul class="flex flex-col space-y-1">
          <li>
            <a
              [routerLink]="['/home/verification-process/welcome']"
              routerLinkActive="bg-gray-100"
              class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-100 text-sm text-gray-800 rounded-lg hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:bg-neutral-700 dark:text-white"
            >
              <svg
                class="shrink-0 size-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              Profile
            </a>
          </li>
        </ul>
      </nav>

      <!-- Merchant Sidebar -->
      <ul
        *ngIf="
          loginUser?.type === '1' && loginUser?.accountStatus !== 'applied'
        "
        class="flex flex-col space-y-1"
      >
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100"
            [routerLink]="['/home/discount-provider/dashboard']"
            routerLinkActive="bg-gray-100"
          >
            <i class="pi pi-fw pi-home"></i>
            Dashboard
          </a>
        </li>

        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100"
            [routerLink]="['/home/discount-provider/mydiscounts']"
            routerLinkActive="bg-gray-100"
          >
            <i class="pi pi-fw pi-percentage"></i>
            My Discounts
          </a>
        </li>

        <li>
          <a
            [routerLink]="['/home/discount-provider/in-pending-request']"
            routerLinkActive="bg-gray-100"
            class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100"
          >
            <i class="pi pi-fw pi-arrow-right-arrow-left"></i>
            In Pending Requests
          </a>
        </li>

        <li>
          <a
            [routerLink]="['/home/discount-provider/out-pending-request']"
            routerLinkActive="bg-gray-100"
            class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100"
          >
            <i class="pi pi-fw pi-arrow-right-arrow-left"></i>
            Out Pending Requests
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/home/discount-provider/our-collaborators']"
            routerLinkActive="bg-gray-100"
            class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100"
          >
            <i class="pi pi-fw pi-users"></i>
            Our Collaborators
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/home/discount-provider/request-for-collab']"
            routerLinkActive="bg-gray-100"
            class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100"
          >
            <i class="pi pi-fw pi-list"></i>
            Request For Collab
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/home/discount-provider/staff-management']"
            routerLinkActive="bg-gray-100"
            class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100"
          >
            <i class="pi pi-fw pi-users"></i>
            Staff Management
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/home/discount-provider/profile']"
            routerLinkActive="bg-gray-100"
            class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100"
          >
            <i class="pi pi-fw pi-user"></i>
            Profile
          </a>
        </li>
      </ul>

      <!-- Corporate Sidebar -->
      <ul
        *ngIf="
          loginUser?.type === '2' && loginUser?.accountStatus !== 'applied'
        "
        class="flex flex-col space-y-1"
      >
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100"
            [routerLink]="['/home/discount-receiver/dashboard']"
            routerLinkActive="bg-gray-100"
          >
            <i class="pi pi-fw pi-home"></i>
            Dashboard
          </a>
        </li>

        <li>
          <a
            [routerLink]="['/home/discount-receiver/in-pending-request']"
            routerLinkActive="bg-gray-100"
            class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100"
          >
            <i class="pi pi-fw pi-arrow-right-arrow-left"></i>
            In Pending Requests
          </a>
        </li>

        <li>
          <a
            [routerLink]="['/home/discount-receiver/out-pending-request']"
            routerLinkActive="bg-gray-100"
            class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100"
          >
            <i class="pi pi-fw pi-arrow-right-arrow-left"></i>
            Out Pending Requests
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/home/discount-receiver/our-collaborators']"
            routerLinkActive="bg-gray-100"
            class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100"
          >
            <i class="pi pi-fw pi-users"></i>
            Our Collaborators
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/home/discount-receiver/request-for-collab']"
            routerLinkActive="bg-gray-100"
            class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100"
          >
            <i class="pi pi-fw pi-list"></i>
            Request For Collab
          </a>
        </li>

        <li>
          <a
            [routerLink]="['/home/discount-receiver/profile']"
            routerLinkActive="bg-gray-100"
            class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100"
          >
            <i class="pi pi-fw pi-user"></i>
            Profile
          </a>
        </li>
      </ul>

      <!-- For Staff Sidebar -->
      <ul
        *ngIf="
          loginUser?.type === '3' && loginUser?.accountStatus !== 'applied'
        "
        class="flex flex-col space-y-1"
      >
        <li>
          <a
            class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100"
            [routerLink]="['/home/staff/dashboard']"
            routerLinkActive="bg-gray-100"
          >
            <i class="pi pi-fw pi-home"></i>
            Dashboard
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/home/staff/profile']"
            routerLinkActive="bg-gray-100"
            class="flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100"
          >
            <i class="pi pi-fw pi-user"></i>
            Profile
          </a>
        </li>
      </ul>

      <!-- For Admin -->
      <ul
      *ngIf="!(loginUser?.type === '1') && !(loginUser?.type==='2') && loginUser?.accountStatus !== 'applied'"
      class="flex flex-col space-y-1"
    >
      <li *ngFor="let module of loginUser?.adminRole?.moduleId">
        <a
          class=" relative flex items-center gap-x-3.5 py-2 px-2.5 text-sm text-gray-700 rounded-lg hover:bg-gray-100 active:bg-gray-100"
          [routerLink]="['/home/ha-admin/' + formatModuleName(module?.name) + '/' + module?._id]"
          routerLinkActive="bg-gray-100"
        >
          <i class="pi pi-fw" [ngClass]="getModuleIcon(module?.name)"></i>
          {{module?.name}}
          <span *ngIf="getItemCount(module?._id) !== 0"  class="flex absolute top-0 end-[8px] -mt-2 -me-2">
     
            <span class="relative inline-flex text-xs bg-red-500 text-white rounded-full py-0.5 px-1.5">
              {{getItemCount(module?._id)}}
            </span>
          </span>
          
        </a>
       
      </li>
   
    </ul>

    </div>
    <!-- End Content -->
  </div>
</div>
