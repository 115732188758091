import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class HaAuthGuard implements CanActivate {
  loginUser: any;

  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(): Promise<boolean | UrlTree> {
    if (this.authService.isLoggedIn()) {
      this.loginUser = await this.authService.fetchLoginUser();
      if (
        this.loginUser?.type === '1' ||
        this.loginUser?.type === '2' ||
        this.loginUser?.type === '3'
      ) {
        return this.router.createUrlTree(['/auth/signin']);
      } else {
        return true;
      }
    }
    // Redirect to the sign-in page if not authenticated or not the correct user type
    return this.router.createUrlTree(['/auth/signin']);
  }
}
