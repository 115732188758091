import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { SocketService } from 'src/app/services/socket.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  notificationList: any = [];
  linkRequestList: any = [];
  unAssignedUserList: any = [];
  selectedEmployee:any = []

  notificationCount: any = 0;
  linkRequestCount:any = 0
  isLoading: boolean = false;
  notificationSidebar: boolean = false;
  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private userService: UserService,
    private socketService: SocketService
  ) {}
  loginUser: any = '';
  async ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.loginUser = await this.authService.fetchLoginUser();
    }
    if (this.loginUser.type === '1') {
      this.dpNotificationList();
    } else if (this.loginUser.type === '2') {
      this.drNotificationList();
      this.getUnassignedUserList();
      this.getLinkRequestList();
    }
    console.log(this.loginUser)

    this.setupSocketListeners();
  }
  getUnassignedUserList() {
    this.isLoading = true;
    this.userService.getDrUnassigendList().subscribe({
      next: (res) => {
        console.log(res);
        this.isLoading = false;
        if (res && res.statusCode === 200) {
          this.unAssignedUserList = res?.data?.empList;
          console.log(this.unAssignedUserList)
          this.getLinkRequestList();
          // this.toastService.show(res.message, true);
        } else {
          this.toastService.show(res.message);
        }
      },
      error: (err) => {
        this.toastService.show(err.message);
        console.error(err);
      },
    });
  }

  getLinkRequestList() {
    this.userService.getDrLinkRequestList().subscribe({
      next: (res) => {
        this.isLoading = false;

        if (res && res.statusCode === 200) {
          console.log(res);
          // this.toastService.show(res.message, true);
          this.linkRequestList = res.data?.linkRequestList;
          this.linkRequestCount = res.data?.unreadLinkRequestCount
        } else {
          this.toastService.show(res.message);
        }
      },
      error: (err) => {
        this.isLoading = false;
        this.toastService.show(err.message);
        console.error(err);
      },
    });
  }

  async handleLogout() {
    const confirm = await this.toastService.confirm(
      'Are you sure you want to log out?'
    );
    if (confirm) {
      this.authService.logout();
    }
  }
  viewNotification() {
    this.notificationSidebar = true;
    this.notificationCount = 0;
    const payload = {
      isRead: true,
    };

    if (this.loginUser.type === '1') {
      this.userService.DpReadNotification(payload).subscribe({
        next: (res) => {
          if (res && res.statusCode === 200) {
            // this.toastService.show(res.message, true);
            this.dpNotificationList();
          } else {
            this.toastService.show(res.message);
          }
        },
        error: (err) => {
          this.toastService.show(err.message);
          console.error(err);
        },
      });
    } else if (this.loginUser.type === '2') {
      this.userService.DrReadNotification(payload).subscribe({
        next: (res) => {
          if (res && res.statusCode === 200) {
            // this.toastService.show(res.message, true);
            this.drNotificationList();
          } else {
            this.toastService.show(res.message);
          }
        },
        error: (err) => {
          this.toastService.show(err.message);
          console.error(err);
        },
      });
    }
  }
  dpNotificationList() {
    this.userService.getDpNotificationList().subscribe({
      next: (res) => {
        if (res && res.statusCode === 200) {
          // this.toastService.show(res.message, true);
          this.notificationList = res.data?.notificationList;
          this.notificationCount = res.data?.unreadNotifications;
          
        } else {
          this.toastService.show(res.message);
        }
      },
      error: (err) => {
        // this.toastService.show(err.message);
        console.error(err);
      },
    });
  }
  drNotificationList() {
    this.userService.getDrNotificationList().subscribe({
      next: (res) => {
        if (res && res.statusCode === 200) {
          // this.toastService.show(res.message, true);
          this.notificationList = res.data?.notificationList;
          this.notificationCount = res.data?.unreadNotifications;
          
        } else {
          this.toastService.show(res.message);
        }
      },
      error: (err) => {
        this.toastService.show(err.message);
        console.error(err);
      },
    });
  }

  setupSocketListeners() {
    this.socketService.onReloadReceiverList().subscribe((id: any) => {
      if (this.loginUser) {
        if (this.loginUser._id === id) {
          if (this.loginUser.type === '1') {
            this.dpNotificationList();
          } else if (this.loginUser.type === '2') {
            this.drNotificationList();
          }
        }
      }
    });
    // This socket for the end tie up
    this.socketService.onReloadReceiverListOurCollab().subscribe((id: any) => {
      if (this.loginUser) {
        if (this.loginUser._id === id) {
          if (this.loginUser.type === '1') {
            this.dpNotificationList();
          } else if (this.loginUser.type === '2') {
            this.drNotificationList();
          }
        }
      }
    });
    this.socketService.onReloadProviderList().subscribe((id) => {
      if (this.loginUser) {
        if (this.loginUser._id === id) {
          if (this.loginUser.type === '1') {
            this.dpNotificationList();
          } else if (this.loginUser.type === '2') {
            this.drNotificationList();
          }
        }
      }
    });
  }

  viewLinkRequest() {
    this.linkRequestCount = 0;
    const payload = {
      isRead: true,
    };
    if(this.loginUser.type === '2'){
      this.userService.DrReadLinkRequest(payload).subscribe({
        next: (res) => {
          if(res && res.statusCode === 200){
            // this.toastService.show(res.message, true);
            this.getLinkRequestList()
          } else {
            this.toastService.show(res.message);
          }
        },
        error: (err) => {
          this.toastService.show(err.message);
          console.error(err);
        }
      });

    }
  }

  assign(item: any) {
    
    if(!this.selectedEmployee){
      return this.toastService.show(`You must select an employee before accept`)
    }
    const payload = {
      reqId:item._id,
      empId: this.selectedEmployee,
      userId: item.userId
    }
    this.isLoading = true;
    this.userService.assignUserToEmployee(payload).subscribe({
        next: (res) => {
          this.isLoading = false;
          console.log(res);
          if(res && res.statusCode === 200){
          this.toastService.show(res.message, true);
          this.getLinkRequestList()
          } else {
          this.toastService.show(res.message);
          }
        },
        error: (err) => {
          this.isLoading = false;
          this.toastService.show(err.message);
          console.error(err);
        }
    });



  }

  async rejectRequest(item:any){
    const confirm = await this.toastService.confirm('Are you sure you want to reject');
    if(confirm){
      const payload = {
        reqId:item._id,
      }
      this.isLoading = true;
      this.userService.rejectLinkRequest(payload).subscribe({
        next: (res) => {
          this.isLoading = false;
          console.log(res);
          if(res && res.statusCode === 200){
          this.toastService.show(res.message, true);
          this.getLinkRequestList()
          } else {
          this.toastService.show(res.message);
          }
        },
        error: (err) => {
          this.isLoading = false;
          this.toastService.show(err.message);
          console.error(err);
        }
      });

    }
  }
  changeEmp(e:any){
    this.selectedEmployee = e.target.value;

  }
}
